'use client';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import React from 'react';

import { Faqs } from '@/app/_types/components/faqs';

import Styles from './FAQs.module.scss';

type FAQsTypes = {
  faqsList: Faqs[];
  preText?: string | null;
  faqTitle?: string | null;
};

const FAQs = ({ faqsList, preText, faqTitle }: FAQsTypes) => {
  const transformFaqItems = (
    faqItems: { question: string; answer: string }[],
  ) => {
    return faqItems.map((item) => ({
      label: item.question,
      children: item.answer,
    }));
  };

  const updatedFaqList = faqsList?.map((section) => {
    const { faq_id } = section;

    return {
      sectionTitle: faq_id?.title,
      faqs: faq_id?.faq_item ? transformFaqItems(faq_id?.faq_item) : [],
    };
  });

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.faqsWrapper}>
          <div className={Styles.sectionHeadingContainer}>
            <div className={Styles.sectionLeftContent}>
              <div className="headingElement">
                {preText && <h3 className="subTitle">{preText}</h3>}
                {faqTitle && <h2 className="titleXl">{faqTitle}</h2>}
              </div>
            </div>
          </div>

          <div className={Styles.faqCategoryCardsSection}>
            {updatedFaqList?.map((item, index) => {
              return (
                <div key={index} className={Styles.faqCategoryCard}>
                  {item?.sectionTitle && (
                    <div className="headingElement">
                      <h3 className="titleMd">{item?.sectionTitle}</h3>
                    </div>
                  )}

                  <Collapse
                    items={item?.faqs}
                    ghost
                    expandIconPosition="end"
                    expandIcon={({ isActive }) =>
                      isActive ? <MinusOutlined /> : <PlusOutlined />
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
