'use client';
import { Button, Collapse, Input } from 'antd';
import cn from 'classnames';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';

import CheckBoxCustom from '@/app/_components/checkbox/CheckBoxCustom';
import ChevronDown from '@/app/_icons/chevron-down.svg';
import FilterIcon from '@/app/_icons/filter.svg';
import SearchIcon from '@/app/_icons/search.svg';
import { MediaResource } from '@/app/_types/components/media-resources';
import { getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './DocumentsListing.module.scss';
import NoResultsFound, { NoResultsDataType } from './NoResultsFound';

type DocumentsListingProps = {
  documentData: MediaResource[];
  noResultsData: NoResultsDataType;
};

const DocumentsListing = (props: DocumentsListingProps) => {
  const { documentData, noResultsData } = props;

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const filterMenuRef = useRef<HTMLDivElement>(null);
  const filterBtnRef = useRef<HTMLAnchorElement>(null);

  const toggleFilter = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsFilterOpen((prev) => !prev);
  };

  const onFilterItemChange = (itemName: string, checked: boolean) => {
    if (!itemName) return;
    setFilterData((prev) =>
      checked ? [...prev, itemName] : prev.filter((item) => item !== itemName),
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        filterMenuRef.current &&
        !filterMenuRef.current.contains(event.target as Node) &&
        filterBtnRef.current &&
        !filterBtnRef.current.contains(event.target as Node)
      ) {
        setIsFilterOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredDocumentData = documentData
    .map((documentItem) => {
      const matchedDocuments = documentItem?.documents?.filter(
        (doc) =>
          doc?.directus_files_id?.title &&
          doc?.directus_files_id?.title
            .toLowerCase()
            .includes(searchQuery.toLowerCase()),
      );

      if (
        (documentItem?.title &&
          documentItem?.title
            .toLowerCase()
            .includes(searchQuery.toLowerCase())) ||
        (matchedDocuments && matchedDocuments.length > 0)
      ) {
        return {
          ...documentItem,
          documents: matchedDocuments || [],
        };
      }

      return null;
    })
    .filter((item) => item !== null);

  const collapseItems = filteredDocumentData
    .filter((documentItem) => documentItem?.documents?.length > 0)
    .map((documentItem, index) => ({
      key: index,
      label: documentItem.title,
      children: documentItem?.documents?.map((doc, docIndex) => (
        <div key={docIndex} className={Styles.documentItemBox}>
          <p className={Styles.documentItemTitle}>
            {doc.directus_files_id?.title || 'Untitled Document'}
          </p>
          <div className={Styles.documentItemDownloadSection}>
            <a
              href={
                getDirectusAssetURI(doc.directus_files_id?.id ?? '', true) ||
                '#'
              }
              target="_blank"
              rel="noopener noreferrer"
              className={Styles.documentItemDownloadLink}
              download={doc.directus_files_id?.title || 'Untitled Document'}
            >
              Download
            </a>
            <a
              href={getDirectusAssetURI(doc.directus_files_id?.id ?? '') || '#'}
              target="_blank"
              rel="noopener noreferrer"
              className={Styles.documentItemDownloadLink}
            >
              View
            </a>
          </div>
        </div>
      )),
    }));

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.documentsListingWrapper}>
          <div className={Styles.searchWrapper}>
            <div className={Styles.searchContainer}>
              <div className={Styles.searchBox}>
                <Input
                  placeholder="Search..."
                  className={Styles.searchBoxInput}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <Button className={Styles.searchBtn}>
                  <Image src={SearchIcon} alt="Search" height={14} width={14} />
                </Button>
              </div>
              <a
                onClick={toggleFilter}
                className={Styles.filterBtn}
                ref={filterBtnRef}
              >
                <span className={Styles.btnText}>Filter</span>
                <Image
                  src={FilterIcon}
                  alt="Filter"
                  className={Styles.btnIcon}
                  height={14}
                  width={14}
                />
              </a>
            </div>
            <div
              ref={filterMenuRef}
              className={cn(Styles.filterMenu, isFilterOpen && Styles.open)}
            >
              {documentData
                .filter((item) => item.documents && item.documents.length > 0)
                .map((item, index) => (
                  <div key={index} className={Styles.filterMenuItem}>
                    <div id={item.id.toString()} className="customInput mb-0">
                      <CheckBoxCustom
                        name={item.title || ''}
                        label={item.title || ''}
                        onChange={(e) =>
                          onFilterItemChange(item.title || '', e.target.checked)
                        }
                        checked={
                          item.title && filterData.includes(item.title)
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {filteredDocumentData && filteredDocumentData.length > 0 ? (
            <div className={Styles.documentListings}>
              <Collapse
                accordion
                expandIconPosition="end"
                bordered={false}
                ghost
                className={Styles.collapseLevel1}
                expandIcon={({ isActive }) => (
                  <Image
                    src={ChevronDown}
                    alt="Chevron Down"
                    className={isActive ? Styles.openBox : Styles.closeBox}
                    height={16}
                    width={16}
                  />
                )}
                items={collapseItems}
              />
            </div>
          ) : (
            <NoResultsFound noResultsData={noResultsData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentsListing;
